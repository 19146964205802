import React from "react";
import styles from "./Sales.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Navigate } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getSalesData } from "../../api/sales";
import Moment from "react-moment";

const Sales = () => {
  function salesData(
    creator,
    sales,
    launchDate,
    instaPoster,
    LinkedInPost,
    signUpGoal,
    signUpTill,
    whatsappMade,
    whatsappSent,
    peopleInGroup,
    ZoomLink,
    calendarInvite
  ) {
    return {
      creator,
      sales,
      launchDate,
      instaPoster,
      LinkedInPost,
      signUpGoal,
      signUpTill,
      whatsappMade,
      whatsappSent,
      peopleInGroup,
      ZoomLink,
      calendarInvite,
    };
  }
  const [rows, setRows] = React.useState([]);

  const fetchAllSales = async () => {
    const { data } = await getSalesData();
    let rowData = data.map((item) => {
      let creator = item["createdBy"];
      let sales = item["salesPerson"];
      let launchDate = item["createdAt"];
      let instaPoster = item["instagramPost"];
      let LinkedInPost = item["linkedinPost"];
      let signUpGoal = item["totalEdits"];
      let signUpTill = item["promosCreated"];
      let whatsappMade = item["whatsappLinkSent"];
      let whatsappSent = item["whatsappLinkSent"];
      let peopleInGroup = item["promosCreated"];
      let ZoomLink = item["promosCreated"];
      let calendarInvite = item["calendarInvite"];

      return salesData(
        creator,
        sales,
        launchDate,
        instaPoster,
        LinkedInPost,
        signUpGoal,
        signUpTill,
        whatsappMade,
        whatsappSent,
        peopleInGroup,
        ZoomLink,
        calendarInvite
      );
    });
    console.log(rowData);
    setRows(rowData);
    console.log(rows);
  };

  React.useEffect(() => {
    fetchAllSales();
    // eslint-disable-next-line
  }, []);

  return JSON.parse(localStorage.getItem("user"))?.role === "sales" ? (
    <div>
      <div className={styles.sales}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name of course creator</TableCell>
              <TableCell align="right">Name of sales person</TableCell>
              <TableCell align="right">Micro course launch date</TableCell>
              <TableCell align="right">Instagram poster been added</TableCell>
              <TableCell align="right">LinkedIn Post</TableCell>
              <TableCell align="right">Goal sign ups</TableCell>
              <TableCell align="right">Sign ups so far</TableCell>
              <TableCell align="right">Whatsapp Link</TableCell>
              <TableCell align="right">Whatsapp link sent</TableCell>
              <TableCell align="right">Feedback given</TableCell>
              <TableCell align="right">Number of people in the group</TableCell>
              <TableCell align="right">Zoom Link</TableCell>
              <TableCell align="right">Calendar invite sent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.creator}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.creator}
                </TableCell>
                <TableCell align="right">{row.sales}</TableCell>
                <TableCell align="right">
                  <Moment fromNow>{row.launchDate}</Moment>
                </TableCell>
                <TableCell align="right">
                  {row.instaPoster ? "Yes" : "No"}
                </TableCell>
                <TableCell align="right">
                  {row.LinkedInPost ? "Yes" : "No"}
                </TableCell>
                <TableCell align="right">{row.signUpGoal}</TableCell>
                <TableCell align="right">{row.signUpTill}</TableCell>
                <TableCell align="right">
                  {row.whatsappMade ? "Yes" : "No"}
                </TableCell>
                <TableCell align="right">
                  {row.whatsappSent ? "Yes" : "No"}
                </TableCell>
                <TableCell align="right">{row.peopleInGroup}</TableCell>
                <TableCell align="right">{row.ZoomLink}</TableCell>
                <TableCell align="right">
                  {row.calendarInvite ? "Yes" : "No"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default Sales;
