import React, { useEffect, useState } from "react";
import styles from "./Admin.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getCreatorDetails } from "../../api/creator";
import { Navigate } from "react-router-dom";
import { getAllEditorList } from "../../api/editor";
import { getSalesData } from "../../api/sales";
import Moment from "react-moment";
import { getAllCourses } from "../../api/course";

const Admin = () => {
  const options = ["Yes", "No"];

  function createData(name, signupDate, lastLogin, podcast, course) {
    return { name, signupDate, lastLogin, podcast, course };
  }

  function podcastData(
    id,
    name,
    appDate,
    appDetails,
    approval,
    editor,
    longFormFilled,
    payment,
    lastDate,
    longFormDetails,
    feedback,
    podcastRecorded,
    editing,
    assets
  ) {
    return {
      id,
      name,
      appDate,
      appDetails,
      approval,
      editor,
      longFormFilled,
      payment,
      lastDate,
      longFormDetails,
      feedback,
      podcastRecorded,
      editing,
      assets,
    };
  }

  function editorData(
    id,
    podcaster,
    editor,
    finalDate,
    episodes,
    completedEdits,
    promosCreated
  ) {
    return {
      id,
      podcaster,
      editor,
      finalDate,
      episodes,
      completedEdits,
      promosCreated,
    };
  }

  function courseData(
    id,
    name,
    appDate,
    formDetails,
    approval,
    distribution,
    longFormFilled,
    payment,
    launch,
    longFormDetails,
    feedback,
    marketingPoster,
    whatsappCreated,
    calenderInvite,
    finalEmail
  ) {
    return {
      id,
      name,
      appDate,
      formDetails,
      approval,
      distribution,
      longFormFilled,
      payment,
      launch,
      longFormDetails,
      feedback,
      marketingPoster,
      whatsappCreated,
      calenderInvite,
      finalEmail,
    };
  }

  function salesData(
    id,
    creator,
    sales,
    launchDate,
    instaPoster,
    LinkedInPost,
    signUpGoal,
    signUpTill,
    whatsappMade,
    whatsappSent,
    peopleInGroup,
    ZoomLink,
    calendarInvite
  ) {
    return {
      id,
      creator,
      sales,
      launchDate,
      instaPoster,
      LinkedInPost,
      signUpGoal,
      signUpTill,
      whatsappMade,
      whatsappSent,
      peopleInGroup,
      ZoomLink,
      calendarInvite,
    };
  }
  // const rows = [createData("User1", "29/08/2022", "30/08/2022", "Yes", "No")];

  const [rows, setRows] = useState([]);

  const [toggleState, setToggleState] = React.useState(1);
  const toggleTabs = (index) => {
    setToggleState(index);
  };

  const getData = async () => {
    if (toggleState === 1) {
      const { data } = await getCreatorDetails();
      let rowData = data.map((item) => {
        let name = item["firstName"] + " " + item["lastName"];
        let signupDate = item["createdAt"];
        let lastLogin = item["lastLoginAt"];
        // signedUpForPodcast:
        let podcast = item["signedUpForPodcast"];
        let course = item["signedUpForCourse"];

        return createData(name, signupDate, lastLogin, podcast, course);
      });
      console.log(rowData);
      setRows(rowData);
      console.log(rows);
    } else if (toggleState === 2) {
      const { data } = await getAllEditorList();
      console.log(data);
      let rData = data.map((item) => {
        let id = item["_id"];
        let name = item["name_creator"];
        let appDate = item["createdAt"];
        let appDetails = item["title"];
        let approval = item["isApproved"];
        let editor = item["editor"]?.username;
        let longFormFilled = item["longPodcastFormFilled"];
        let payment = item["podcastRecorded"];
        let lastDate = item["updatedAt"];
        let longFormDetails = item["name_creator"];
        let feedback = item["feedbackGiven"];
        let podcastRecorded = item["podcastRecorded"];
        let editing = item["podcastEdited"];
        let assets = item["podcastDelievered"];

        return podcastData(
          id,
          name,
          appDate,
          appDetails,
          approval,
          editor,
          longFormFilled,
          payment,
          lastDate,
          longFormDetails,
          feedback,
          podcastRecorded,
          editing,
          assets
        );
      });
      console.log(rData);
      setRows(rData);
      console.log(rows);
    } else if (toggleState === 3) {
      const { data } = await getAllEditorList();
      let rowData = data.map((item) => {
        let id = item["_id"];
        let podcaster = item["name_creator"];
        let editor = item["editor"]?.username;
        let finalDate = item["lastDateForShoot"];
        let episodes = item["episodesRecieved"];
        let completedEdits = item["totalEdits"];
        let promosCreated = item["promosCreated"];

        return editorData(
          id,
          podcaster,
          editor,
          finalDate,
          episodes,
          completedEdits,
          promosCreated
        );
      });
      console.log(rowData);
      setRows(rowData);
      console.log(rows);
    } else if (toggleState === 4) {
      const { course } = await getAllCourses();
      let rowData = course.map((item) => {
        let id = item["_id"];
        let name = item["name"];
        let appDate = item["startsAt"];
        let formDetails = item["description"];
        let approval = item["isVerified"];
        let distribution = item["distributor"];
        let longFormFilled = false;
        let payment = item["hasPaid"];
        let launch = item["startsAt"];
        let longFormDetails = "";
        let feedback = item["description"];
        let marketingPoster = item["courseImg"];
        let whatsappCreated = item["whatsappUrl"];
        let calenderInvite = item["isActive"];
        let finalEmail = item["isActive"];

        return courseData(
          id,
          name,
          appDate,
          formDetails,
          approval,
          distribution,
          longFormFilled,
          payment,
          launch,
          longFormDetails,
          feedback,
          marketingPoster,
          whatsappCreated,
          calenderInvite,
          finalEmail
        );
      });
      console.log(rowData);
      setRows(rowData);
      console.log(rows);
    } else if (toggleState === 5) {
      const { data } = await getSalesData();
      let rowData = data.map((item) => {
        let id = item["_id"];
        let creator = item["createdBy"];
        let sales = item["salesPerson"];
        let launchDate = item["createdAt"];
        let instaPoster = item["instagramPost"];
        let LinkedInPost = item["linkedinPost"];
        let signUpGoal = item["signUpGoals"];
        let signUpTill = item["signUpAchieved"];
        let whatsappMade = item["whatsappLinkSent"];
        let whatsappSent = item["whatsappLinkSent"];
        let peopleInGroup = item["totalPeopleInGroup"];
        let ZoomLink = item["zoomLink"];
        let calendarInvite = item["calendarInvite"];

        return salesData(
          id,
          creator,
          sales,
          launchDate,
          instaPoster,
          LinkedInPost,
          signUpGoal,
          signUpTill,
          whatsappMade,
          whatsappSent,
          peopleInGroup,
          ZoomLink,
          calendarInvite
        );
      });
      console.log(rowData);
      setRows(rowData);
      console.log(rows);
    }
  };

  useEffect(() => {
    getData();
    console.log(JSON.parse(localStorage.getItem("user"))?.role);
    // eslint-disable-next-line
  }, [toggleState]);

  return JSON.parse(localStorage.getItem("user"))?.role === "admin" ? (
    <div>
      <div className={styles.row}>
        <Button
          variant={toggleState === 1 ? "contained" : "outlined"}
          size="large"
          className={styles.button}
          onClick={() => toggleTabs(1)}
        >
          General
        </Button>
        <Button
          variant={toggleState === 2 ? "contained" : "outlined"}
          size="large"
          className={styles.button}
          onClick={() => toggleTabs(2)}
        >
          Podcast
        </Button>
        <Button
          variant={toggleState === 3 ? "contained" : "outlined"}
          size="large"
          className={styles.button}
          onClick={() => toggleTabs(3)}
        >
          Editor Board
        </Button>
        <Button
          variant={toggleState === 4 ? "contained" : "outlined"}
          size="large"
          className={styles.button}
          onClick={() => toggleTabs(4)}
        >
          Courses
        </Button>
        <Button
          variant={toggleState === 5 ? "contained" : "outlined"}
          size="large"
          className={styles.button}
          onClick={() => toggleTabs(5)}
        >
          Sales Board
        </Button>
      </div>
      <div
        className={toggleState === 1 ? `${styles.general}` : `${styles.none}`}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Signup Date</TableCell>
              <TableCell align="right">Last Login&nbsp;</TableCell>
              <TableCell align="right">Signed up for podcast&nbsp;</TableCell>
              <TableCell align="right">Signed up for course&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.name + index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {console.log(row)}
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  <Moment date={row.signupDate} format="Do MMM, YY" />
                </TableCell>
                <TableCell align="right">
                  <Moment fromNow>{row.lastLogin}</Moment>
                </TableCell>
                <TableCell align="right" size="small">
                  <Autocomplete
                    value={row.podcast ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.course ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button variant="outlined" className={styles.button}>
          Make general changes
        </Button>
      </div>
      <div
        className={toggleState === 2 ? `${styles.podcast}` : `${styles.none}`}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Podcast application Date</TableCell>
              <TableCell align="right">Application detail</TableCell>
              <TableCell align="right">Approve/Reject</TableCell>
              <TableCell align="right">Editor assigned</TableCell>
              <TableCell align="right">Long podcast form filled</TableCell>
              <TableCell align="right">Payment made</TableCell>
              <TableCell align="right">
                Last date for their podcast to be shot?
              </TableCell>
              <TableCell align="right">Long podcast form details</TableCell>
              <TableCell align="right">Feedback given</TableCell>
              <TableCell align="right">Podcast recorded</TableCell>
              <TableCell align="right">Editing done</TableCell>
              <TableCell align="right">Assets delivered?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.appDate}</TableCell>
                <TableCell align="right">{row.appDetails}</TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.approval ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    id="standard-size-small"
                    defaultValue={row.editor}
                    variant="standard"
                    size="small"
                    style={{ margin: "15px" }}
                  />
                </TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.longFormFilled ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.payment ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">{row.lastDate}</TableCell>
                <TableCell align="right">{row.longFormDetails}</TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.feedback ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.podcastRecorded ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.editing ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.assets ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button variant="outlined" className={styles.button}>
          Make podcast changes
        </Button>
      </div>
      <div
        className={toggleState === 3 ? `${styles.editor}` : `${styles.none}`}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name of podcaster</TableCell>
              <TableCell align="right">Name of editor</TableCell>
              <TableCell align="right">
                Final date for podcast to be shot
              </TableCell>
              <TableCell align="right">Episodes received?</TableCell>
              <TableCell align="right">How many edits complete?</TableCell>
              <TableCell align="right">2 Promos created per podcast?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {console.log(rows)}
            {rows.map((row) => (
              <TableRow
                key={row?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.podcaster}
                </TableCell>
                <TableCell align="right">
                  {!row.editor ? "Not Assigned" : row.editor}
                </TableCell>
                <TableCell align="right">
                  {!row.finalDate ? "Not Decided" : row.finalDate}
                </TableCell>
                <TableCell align="right">
                  <TextField
                    id="standard-size-small"
                    defaultValue={row?.episodes}
                    variant="standard"
                    size="small"
                    style={{ padding: "15px" }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    id="standard-size-small"
                    defaultValue={row?.completedEdits}
                    variant="standard"
                    size="small"
                    style={{ padding: "15px" }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    id="standard-size-small"
                    defaultValue={row?.promosCreated}
                    variant="standard"
                    size="small"
                    style={{ padding: "15px" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button variant="outlined" className={styles.button}>
          Make editor changes
        </Button>
      </div>
      <div
        className={toggleState === 4 ? `${styles.courses}` : `${styles.none}`}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Micro course application date</TableCell>
              <TableCell align="right">Micro course form details</TableCell>
              <TableCell align="right">Approve/Reject</TableCell>
              <TableCell align="right">Distribution person assigned</TableCell>
              <TableCell align="right">Long micro course form filled</TableCell>
              <TableCell align="right">Payment made</TableCell>
              <TableCell align="right">Launch date</TableCell>
              <TableCell align="right">Long course form details</TableCell>
              <TableCell align="right">Feedback given</TableCell>
              <TableCell align="right">Marketing poster shared</TableCell>
              <TableCell align="right">Whatsapp group created?</TableCell>
              <TableCell align="right">Calendar invites sent</TableCell>
              <TableCell align="right">
                Final email sent to course creator?
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.appDate}</TableCell>
                <TableCell align="right">{row.formDetails}</TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.approval ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.distribution ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.longFormFilled ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.payment ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">{row.launch}</TableCell>
                <TableCell align="right">{row.longFormDetails}</TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.feedback ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.marketingPoster ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">{row.whatsappCreated}</TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.calenderInvite ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
                <TableCell align="right">
                  <Autocomplete
                    value={row.finalEmail ? options[0] : options[1]}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button variant="outlined" className={styles.button}>
          Make courses changes
        </Button>
      </div>
      <div className={toggleState === 5 ? `${styles.sales}` : `${styles.none}`}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name of course creator</TableCell>
              <TableCell align="right">Name of sales person</TableCell>
              <TableCell align="right">Micro course launch date</TableCell>
              <TableCell align="right">Instagram poster been added</TableCell>
              <TableCell align="right">LinkedIn Post</TableCell>
              <TableCell align="right">Goal sign ups</TableCell>
              <TableCell align="right">Sign ups so far</TableCell>
              <TableCell align="right">Whatsapp Link</TableCell>
              <TableCell align="right">Whatsapp link sent</TableCell>
              <TableCell align="right">Feedback given</TableCell>
              <TableCell align="right">Number of people in the group</TableCell>
              <TableCell align="right">Zoom Link</TableCell>
              <TableCell align="right">Calendar invite sent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.creator}
                </TableCell>
                <TableCell align="right">{row.sales}</TableCell>
                <TableCell align="right">
                  <Moment fromNow>{row.launchDate}</Moment>
                </TableCell>
                <TableCell align="right">
                  {row.instaPoster ? "Yes" : "No"}
                </TableCell>
                <TableCell align="right">
                  {row.LinkedInPost ? "Yes" : "No"}
                </TableCell>
                <TableCell align="right">{row.signUpGoal}</TableCell>
                <TableCell align="right">{row.signUpTill}</TableCell>
                <TableCell align="right">
                  {row.whatsappMade ? "Yes" : "No"}
                </TableCell>
                <TableCell align="right">
                  {row.whatsappSent ? "Yes" : "No"}
                </TableCell>
                <TableCell align="right">{row.peopleInGroup}</TableCell>
                <TableCell align="right">{row.ZoomLink}</TableCell>
                <TableCell align="right">
                  {row.calendarInvite ? "Yes" : "No"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button variant="outlined" className={styles.button}>
          Make sales changes
        </Button>
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default Admin;
