// later will push it to .env:
const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;

const { fetchWrapper, errorToastLogger } = require("../utils/utils");

export const getCreatorDetails = async () => {
  const reqUrl = `${backendApiUrl}/user/creator-details`;
  console.log("Hello");
  console.log(reqUrl);

  let response;
  try {
    response = await fetchWrapper(reqUrl, "", {}, false);
    const data = await response.json();

    if (!data || !data.success) {
      errorToastLogger(
        "getCreatorDetails",
        data?.message || "Failed to get creator details"
      );
      return false;
    }
    return data;
  } catch (err) {
    errorToastLogger("getCreatorDetails", "Failed to get creator details", err);
    return false;
  }
};

export const updateCreatorDetails = async (details, creatorId) => {
  const reqUrl = `${backendApiUrl}/user/${creatorId}`;

  let response;

  try {
    // pass in data to be updated:
    response = await fetchWrapper(reqUrl, details, "", "", "PATCH");
    const data = await response.json();
    if (!data || !data.success) {
      errorToastLogger(
        "updateCreatorDetails",
        data?.message || "Failed to update creator details"
      );
      return false;
    }
    return true;
  } catch (err) {
    errorToastLogger(
      "updateCreatorDetails",
      "Failed to update creator details",
      err
    );
    return false;
  }
};
