import { fetchWrapper, errorToastLogger } from "../utils/utils";
const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;

export const getAccessToken = async (refreshToken) => {
  const reqUrl = `${backendApiUrl}/user/access-token`;
  let response;

  try {
    response = await fetchWrapper(reqUrl, { refreshToken }, "", true);
    const data = await response.json();
    if (!data || !data.success) {
      errorToastLogger(
        "getAccessToken",
        data?.message || "Failed to get access token"
      );
      return false;
    }
    return data;
  } catch (err) {
    errorToastLogger("getAccessToken", "Failed to get access token", err);
    return false;
  }
};

export const loginUser = async (credential, clientId, isCreator = false) => {
  const reqUrl = `${backendApiUrl}/user/login`;
  let response;

  try {
    response = await fetchWrapper(
      reqUrl,
      { token: credential, clientId, isCreator: isCreator ? true : false },
      "",
      true
    );
    const data = await response.json();
    if (!data || !data.success) {
      errorToastLogger("loginUser", data?.message || "Failed to login user");
      return false;
    }
    return data;
  } catch (err) {
    errorToastLogger("loginUser", "Failed to login user", err);
    return false;
  }
};

export const isLogin = () => {
  if (localStorage.getItem("user")) {
      return true;
  }

  return false;
}