import React from "react";
import styles from "./Editor.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getAllEditorList } from "../../api/editor";
import Moment from "react-moment";
import { Navigate } from "react-router-dom";

const Editor = () => {
  function editorData(
    id,
    podcaster,
    editor,
    finalDate,
    episodes,
    completedEdits,
    promosCreated
  ) {
    return {
      id,
      podcaster,
      editor,
      finalDate,
      episodes,
      completedEdits,
      promosCreated,
    };
  }
  const [rows, setRows] = React.useState([]);

  const getData = async () => {
    const { data } = await getAllEditorList();
      let rowData = data.map((item) => {
        let id = item["_id"];
        let podcaster = item["name_creator"];
        let editor = item["editor"]?.username;
        let finalDate = item["lastDateForShoot"];
        let episodes = item["episodesRecieved"];
        let completedEdits = item["totalEdits"];
        let promosCreated = item["promosCreated"];

        return editorData(
          id,
          podcaster,
          editor,
          finalDate,
          episodes,
          completedEdits,
          promosCreated
        );
      });
      console.log(rowData);
      setRows(rowData);
      console.log(rows);
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  // const fetchAllEditors = async () => {
  //   const res = await getAllEditorList();
  //   if (!res) return;

  //   setRows(res || []);
  // };

  // React.useEffect(() => {
  //   fetchAllEditors();
  // }, []);

  return JSON.parse(localStorage.getItem("user"))?.role === "editor" ? (
    <div>
      <div className={styles.editor}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name of podcaster</TableCell>
              <TableCell align="right">Name of editor</TableCell>
              <TableCell align="right">
                Final date for podcast to be shot
              </TableCell>
              <TableCell align="right">Episodes received?</TableCell>
              <TableCell align="right">How many edits complete?</TableCell>
              <TableCell align="right">2 Promos created per podcast?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.podcaster}
                </TableCell>
                <TableCell align="right">
                  {row.editor == null ? "Not Assigned" : row.editor}
                </TableCell>
                <TableCell align="right">
                  <Moment fromNow>{row.finalDate}</Moment>
                </TableCell>
                <TableCell align="right">{row.episodes}</TableCell>
                <TableCell align="right">{row.completedEdits}</TableCell>
                <TableCell align="right">
                  {row.promosCreated ? "Yes" : "No"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default Editor;
