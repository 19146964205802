import React, {  useRef } from "react";
import { Button } from "@mui/material";

import Spinner from "../../components/Spinner/Spinner";

import styles from "./AuthPage.module.scss";

function AuthPage({ isCreator, ...props }) {
  const googleSignInButton = useRef();

  return (
    <div className={styles.container}>
      <div className={styles.right}>
        <div className={styles.box}>
          <p className={styles.title}>Welcome to the CRM Dashboard,</p>

          <Button
            variant="contained"
            color="primary"
            className={styles.googleButton}
            onClick={() => googleSignInButton.current.click()}
            disabled={props.googleLoginLoading}
          >
            <div
              ref={googleSignInButton}
              id="g_id_signin"
              className={`g_id_signin ${styles.originalButton}`}
              data-width="390"
            />
            {props.googleLoginLoading && <Spinner white small noMargin />}
            Google Login
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AuthPage;
