import toast from "react-hot-toast";
import { getAccessToken } from "../api/auth";

export const fetchWrapper = async (
  url,
  data = "",
  headers = {},
  isPublic = false,
  requestType = "",
  sendDataAsItIs = false
) => {
  const fetchOptions = {
    method: requestType || (data ? "POST" : "GET"),
    headers: {
      ...headers,
    },
  };

  if (!isPublic) {
    let refreshToken;
    try {
      const refreshTokenObject = JSON.parse(
        localStorage.getItem("refreshToken")
      );
      const expiry = refreshTokenObject.expiryDate;
      if (new Date(expiry) <= new Date()) refreshToken = "";
      else refreshToken = refreshTokenObject.token;
    } catch (_err) {
      refreshToken = "";
    }
    if (!refreshToken) {
      handleLogout();
      throw new Error("Refresh-Token not available");
    }

    let accessToken;
    try {
      const accessTokenObject = JSON.parse(localStorage.getItem("accessToken"));
      const expiry = accessTokenObject.expiryDate;
      if (new Date(expiry) <= new Date()) accessToken = "";
      else accessToken = accessTokenObject.token;
    } catch (_err) {
      accessToken = "";
    }

    if (!accessToken) {
      const res = await getAccessToken(refreshToken);
      if (res.accessToken?.accessTokenJWT) {
        const accessTokenObj = {
          token: res?.accessToken?.accessTokenJWT,
          expiryDate: (parseInt(res?.accessToken?.expiresIn) || 0) + Date.now(),
        };
        accessToken = res.accessToken?.accessTokenJWT;
        localStorage.setItem("accessToken", JSON.stringify(accessTokenObj));
      }
    }

    fetchOptions.headers["Authorization"] = accessToken
      ? `Bearer ${accessToken}`
      : "";
  }

  if (sendDataAsItIs) {
    fetchOptions.body = data;
  } else if (data && typeof data === "object") {
    fetchOptions.body = JSON.stringify(data);
    fetchOptions.headers["Content-Type"] = "application/json";
  }
  return fetch(url, fetchOptions);
};

export const errorToastLogger = (functionName, message, error) => {
  if (message) {
    toast.error("" + message);
    console.error(`Error at ${functionName} : ${error ? error : message}`);
    return;
  }

  console.error(`Error at ${functionName} : ${error ? error : ""}`);
};

export const handleLogout = () => {
  localStorage.clear();
  window.location.replace("/");
};