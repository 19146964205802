const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;

const { fetchWrapper, errorToastLogger } = require("../utils/utils");

// we can get and update data for editor screen using podcast functions
export const assignEditor = async (details) => {
  const reqUrl = `${backendApiUrl}/video-podcast/assign-editor`;

  let response;

  try {
    response = await fetchWrapper(reqUrl, details, "", false, "POST");
    const data = await response.json();

    if (!data || !data.success) {
      errorToastLogger(
        "assignEditor",
        data?.message || "Failed to assign editor to podcast!"
      );
      return false;
    }
    return data;
  } catch (err) {
    errorToastLogger("assignEditor", "Failed to assign editor to podcast", err);
    return false;
  }
};

export const getAllEditorList = async () => {
  const reqUrl = `${backendApiUrl}/video-podcast/public`;
  let response;

  try {
    response = await fetchWrapper(reqUrl, "", {}, true, "GET");
    const data = await response.json();
    if (!data || !data.success) {
      errorToastLogger(
        "getAllEditorList",
        data?.message || "Failed to getAllEditorList"
      );
      return false;
    }
    return data;
  } catch (err) {
    errorToastLogger("getAllEditorList", "Failed to getAllEditorList", err);
    return false;
  }
};
