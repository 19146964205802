import { useEffect, useState } from "react";

import AuthPage from "../../modules/AuthPage";
import { Navigate } from "react-router-dom";

import { loginUser } from "../../api/auth";

function Auth() {
  const [googleLoginButtonDisabled, setGoogleLoginButtonDisabled] =
    useState(true);

  const handleGoogleSignIn = async (obj) => {
    const { credential, clientId } = obj;
    setGoogleLoginButtonDisabled(true);

    const res = await loginUser(credential, clientId, false);
    if (!res || !res.user || !res.tokens) {
      setGoogleLoginButtonDisabled(false);
      return;
    }

    const user = {
      id: res.user._id,
      username: res.user.username,
      firstName: res.user.firstName,
      lastName: res.user.lastName,
      profileImage: res.user.profileImg,
      email: res.user.email,
      phone: res.user.phone,
      communitiesJoined: res.user.communitiesJoined,
      communitiesCreated: res.user.communitiesCreated,
      isCreator: res.user.isCreator,
      role: res.user?.role,
    };
    if (res.isNewUser) localStorage.setItem("isNewUser", true);

    const accessToken = {
      token: res.tokens?.accessToken?.accessTokenJWT,
      expiryDate:
        (parseInt(res.tokens?.accessToken?.expiresIn) || 0) + Date.now(),
    };
    const refreshToken = {
      token: res.tokens?.refreshToken?.refreshTokenJWT,
      expiryDate:
        (parseInt(res.tokens?.refreshToken?.expiresIn) || 0) + Date.now(),
    };
    localStorage.setItem("accessToken", JSON.stringify(accessToken));
    localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
    localStorage.setItem("user", JSON.stringify(user));

    setGoogleLoginButtonDisabled(false);
  };

  const initializeGsi = () => {
    if (!window.google) return;
    setTimeout(() => {
      setGoogleLoginButtonDisabled(false);
    }, 1000);
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleGoogleSignIn,
    });
    window.google.accounts.id.renderButton(
      document.getElementById("g_id_signin"),
      { theme: "outline", size: "large", width: 390 }
    );
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = initializeGsi;
    script.async = true;
    script.id = "google-client-script";
    document.body.appendChild(script);

    return () => {
      if (window.google) window.google.accounts.id.cancel();

      document.getElementById("google-client-script")?.remove();
    };
    // eslint-disable-next-line
  }, []);

  const userRole = JSON.parse(localStorage.getItem("user"))?.role;

  return JSON.parse(localStorage.getItem("user")) ? (
    <Navigate to={"/" + userRole} />
  ) : (
    <div>
      {
        <AuthPage
          googleLoginLoading={googleLoginButtonDisabled}
          isCreator={false}
        />
      }
    </div>
  );
}

export default Auth;
