const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;

const { fetchWrapper, errorToastLogger } = require("../utils/utils");

export const getSalesData = async () => {
  const reqUrl = `${backendApiUrl}/sales/`;

  let response;

  try {
    response = await fetchWrapper(reqUrl, "", "", false, "GET");
    const data = await response.json();

    if (!data || !data.success) {
      errorToastLogger(
        "getSalesData",
        data?.message || "Failed to get sales data!"
      );
      return false;
    }
    return data;
  } catch (err) {
    errorToastLogger("getSalesData", "Failed to get sales data", err);
    return false;
  }
};

export const createSales = async (details) => {
  const reqUrl = `${backendApiUrl}/sales/`;

  let response;

  try {
    response = await fetchWrapper(reqUrl, details, "", false, "POST");
    const data = await response.json();

    if (!data || !data.success) {
      errorToastLogger(
        "createSales",
        data?.message || "Failed to create sales entry!"
      );
      return false;
    }
    return data;
  } catch (err) {
    errorToastLogger("createSales", "Failed to create sales entry", err);
    return false;
  }
};

export const updateSales = async (details, salesId) => {
  const reqUrl = `${backendApiUrl}/sales/${salesId}`;

  let response;

  try {
    response = await fetchWrapper(reqUrl, details, "", false, "PATCH");
    const data = await response.json();

    if (!data || !data.success) {
      errorToastLogger(
        "updateSales",
        data?.message || "Failed to update sales entry!"
      );
      return false;
    }
    return data;
  } catch (err) {
    errorToastLogger("updateSales", "Failed to update sales entry", err);
    return false;
  }
};

export const deleteSales = async (salesId) => {
  const reqUrl = `${backendApiUrl}/sales/${salesId}`;

  let response;

  try {
    response = await fetchWrapper(reqUrl, "", "", false, "DELETE");
    const data = await response.json();

    if (!data || !data.success) {
      errorToastLogger(
        "deleteSales",
        data?.message || "Failed to delete sales entry!"
      );
      return false;
    }
    return data;
  } catch (err) {
    errorToastLogger("deleteSales", "Failed to delete sales entry", err);
    return false;
  }
};

// assign to a salesperson:
export const assignDistributor = async (details) => {
  const reqUrl = `${backendApiUrl}/course/assign-distributor`;

  let response;

  try {
    response = await fetchWrapper(reqUrl, "", "", false, "POST");
    const data = await response.json();

    if (!data || !data.success) {
      errorToastLogger(
        "assignDistributor",
        data?.message || "Failed to assign distributor!"
      );
      return false;
    }
    return data;
  } catch (err) {
    errorToastLogger("assignDistributor", "Failed to assign distributor", err);
    return false;
  }
};
