import React from "react";
import { Route, Routes } from "react-router-dom";
import Admin from "./pages/AdminScreen";
import Login from "./pages/LoginScreen";
import Sales from "./pages/SalesScreen";
import Editor from "./pages/EditorScreen";
import User from "./pages/UserScreen";

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/sales" element={<Sales />} />
        <Route exact path="/editor" element={<Editor />} />
        <Route exact path="/user" element={<User />} />
      </Routes>
    </div>
  );
}

export default App;
