import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBY4qkM4SWM9DwPYjkFun_G7kIhlMlJjKA",
  authDomain: "staging-crm-c2c97.firebaseapp.com",
  projectId: "staging-crm-c2c97",
  storageBucket: "staging-crm-c2c97.appspot.com",
  messagingSenderId: "1037424069037",
  appId: "1:1037424069037:web:ee99202613304dd8093924",
  measurementId: "G-2SVM8BNMY8"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
