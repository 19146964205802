const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;

const { fetchWrapper, errorToastLogger } = require("../utils/utils");

export const approveCourse = async (details) => {
  const reqUrl = `${backendApiUrl}/course/approve-course/`;

  let response;

  try {
    response = await fetchWrapper(reqUrl, details, "", false, "POST");
    const data = await response.json();

    if (!data || !data.success) {
      errorToastLogger(
        "approveCourse",
        data?.message || "Failed to approve course!"
      );
      return false;
    }
    return data;
  } catch (err) {
    errorToastLogger("approveCourse", "Failed to approve course", err);
    return false;
  }
};

export const getAllCourses = async (isPublic = false) => {
  const reqUrl = isPublic
    ? `${backendApiUrl}/course/public/get/all`
    : `${backendApiUrl}/course/get/all`;
  let response;

  try {
    response = await fetchWrapper(
      reqUrl,
      {
        projection: {
          channels: 0,
          members: 0,
          updatedBy: 0,
          createdAt: 0,
          updatedAt: 0,
          isDeleted: 0,
          reportings: 0,
          dayDetails: 0,
          faq: 0,
        },
      },
      {},
      isPublic,
      "POST"
    );
    const data = await response.json();
    if (!data || !data.success) {
      errorToastLogger(
        "getAllCourses",
        data?.error || "Failed to get all courses"
      );
      return false;
    }

    return data;
  } catch (err) {
    errorToastLogger("getAllCourses", "Failed to get all courses", err);
    return false;
  }
};
